import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/About.css"; // Create this file for styles
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import Header from "./Header";
import Menu from "./Menu";
import { useParams, useNavigate } from "react-router-dom";

const About = ({
  articles,
  setIsArticleSelected,
  searchQuery,
  selectedArticle,
  handleSearchChange,
  setSearchQuery,
  setSelectedArticle,
  isArticleSelected,
}) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WK7CFJHC", // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const navigate = useNavigate(); // Add useNavigate hook

  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
  const handleBackClick = () => {
    setSelectedArticle(null);
    if (setIsArticleSelected && typeof setIsArticleSelected === "function") {
      setIsArticleSelected(false);
    }
    navigate("/");
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal open/close
  };
  return (
    <div className="container">
      <Header
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        setSearchQuery={setSearchQuery}
        isArticleSelected={isArticleSelected}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        setIsArticleSelected={setIsArticleSelected}
      />
      <div className="about">
        <Helmet>
          <title>About Us - Easy Fishkeeping</title>
          <meta
            name="description"
            content="Learn more about Easy Fishkeeping and our mission to help you with aquascaping and fishkeeping."
          />
        </Helmet>
        <Menu isModalOpen={isModalOpen} toggleModal={toggleModal} />

        <h1><button
              className="back-button"
              onClick={handleBackClick}
              aria-label="Go back to article list"
            ></button>
            &nbsp;&nbsp;About Us</h1>
        <p>
        At Easy Fishkeeping, we believe in making the art of aquascaping and fishkeeping accessible to everyone—from beginners to experienced enthusiasts. Whether you’re looking for expert tips, practical guides, or creative inspiration to build the perfect aquarium, we’ve got you covered.<br/><br/>

With a passion for marine life and aquatic beauty, we share proven techniques, in-depth articles, and step-by-step tutorials to help you create and maintain stunning underwater worlds. Our goal is to simplify the process while enhancing the joy of keeping a healthy and thriving aquarium.<br/><br/>

Explore, Learn, and Dive into a world of aquatic wonder with Easy Fishkeeping!
        </p>
        {/* Add more content as needed */}
      </div>
      <div className="button-container">
        <button className="primary" onClick={() => navigate("/")}>
          &#8962;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#8593;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#128269;
        </button>
      </div>
    </div>
  );
};

export default About;
