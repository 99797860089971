import React, { useState, useEffect, useCallback } from "react";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import "../styles/Body.css";
import TagManager from 'react-gtm-module';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce'; // Import debounce
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import YouTubeVideos from "./YouTubeVideos";

const Body = ({
  searchQuery,
  setSearchQuery,
  setIsArticleSelected,
  selectedArticle,
  setSelectedArticle,
}) => {

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WK7CFJHC',  // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const location = useLocation(); // Get current URL

  const logSiteVisit = useCallback(
    debounce(async () => {
      try {
        // Session ID should be set by the backend cookie
        await axios.post('/api/site-visit', { page: location.pathname }, { withCredentials: true });
      } catch (error) {
        console.error('Error logging site visit:', error);
      }
    }, 2000), // Debounce time of 2000ms (2 seconds)
    [location]
  );

  useEffect(() => {
    logSiteVisit();
  }, [logSiteVisit]);

  const [articles, setArticles] = useState([]);
  const [visibleArticles, setVisibleArticles] = useState(4); // Initially show 4 articles
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const ARTICLES_INCREMENT = 6; // Number of articles to load each time

  // Fetch articles on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/articles/articles.json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

   // Save the current `visibleArticles` to sessionStorage before navigating away
   useEffect(() => {
    sessionStorage.setItem("visibleArticles", visibleArticles);
  }, [visibleArticles]);


  // Reset selected article and visible articles when searchQuery changes
  useEffect(() => {
    setSelectedArticle(null);
    setVisibleArticles(ARTICLES_INCREMENT);
    if (setIsArticleSelected && typeof setIsArticleSelected === "function") {
      setIsArticleSelected(false);
    }
  }, [searchQuery, setIsArticleSelected]);

  // Filter articles based on searchQuery
  const filteredArticles = articles
    .map((article) => {
      const queryWords =
        typeof searchQuery === "string"
          ? searchQuery.toLowerCase().split(/\s+/)
          : [];

      const countMatchingWords = (text) => {
        if (typeof text !== "string") return 0;

        const lowerText = text.toLowerCase();
        return queryWords.reduce((count, word) => {
          return lowerText.includes(word) ? count + 1 : count;
        }, 0);
      };

      const totalMatches =
        countMatchingWords(article.title) +
        countMatchingWords(article.description) +
        countMatchingWords(article.details);

      return { ...article, totalMatches };
    })
    .filter((article) => article.totalMatches > 0)
    .sort((a, b) => b.totalMatches - a.totalMatches);

  // Load more articles
  const loadMoreArticles = useCallback(() => {
    console.log("Load More Artciles ... ");
    setVisibleArticles((prevVisibleArticles) => {
      const newVisibleArticles = prevVisibleArticles + ARTICLES_INCREMENT;
      return Math.min(newVisibleArticles, filteredArticles.length);
    });
  }, [filteredArticles.length]);
  

   
  // Intersection Observer to load more articles
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && visibleArticles < filteredArticles.length) {
        console.log("Sentinel is intersecting. Loading more articles...");
        loadMoreArticles();
      }
    }, { rootMargin: '100px' });
  
    const sentinel = document.querySelector('.loading');
    if (sentinel) {
      console.log("Observer attached to sentinel");
      observer.observe(sentinel);
    }
  
    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [loadMoreArticles, visibleArticles, filteredArticles.length]);
  

  const logArticleVisit = async (article) => {
    if (article) {
      try {
        const response = await fetch('/api/article-visit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ article_id: article.id }),
        });
        if (!response.ok) {
          throw new Error('Failed to log article visit');
        }
      } catch (error) {
        console.error('Error logging article visit:', error);
      }
    }
  };

  const handleArticleClick = async (article) => {
    logArticleVisit(article);
    setSelectedArticle(article);
    if (setIsArticleSelected && typeof setIsArticleSelected === "function") {
      setIsArticleSelected(true);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    try {
      await axios.post('/api/article-visit', { articleId: article.id }, { withCredentials: true });
    } catch (error) {
      console.error('Error logging article visit:', error);
    }
  };
  // Handle article click and navigate back
  const navigate = useNavigate();

  const handleBackClick = () => {
    setSelectedArticle(null);
    setVisibleArticles(ARTICLES_INCREMENT); // Reset the visible articles count

    if (setIsArticleSelected && typeof setIsArticleSelected === "function") {
      setIsArticleSelected(false);
    }
    navigate("/");
  };

  const generateShareableLink = (article) => {
    // Replace spaces with hyphens for the URL
    const urlTitle = article.title.replace(/\s+/g, '-');
    const url = `${window.location.origin}/article/${urlTitle}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
};


  // Function to handle image error
  const handleImageError = (event) => {
    event.target.src = './images/fallback.webp'; // Set fallback image
  };

  return (
    <div className="body">
       
      <Helmet>
        <title>
          {selectedArticle ? selectedArticle.title : "Featured Articles"}
        </title>
        <meta
          name="description"
          content={
            selectedArticle
              ? selectedArticle.description
              : "Browse through our featured articles on aquascaping."
          }
        />
        {/* Open Graph for Social Media */}
        <meta
          property="og:title"
          content={
            selectedArticle
              ? selectedArticle.title
              : "Aquascaping Basics - Easy Fishkeeping"
          }
        />
        <meta
          property="og:description"
          content={
            selectedArticle
              ? selectedArticle.description
              : "Explore aquascaping techniques, tips, and more with Easy Fishkeeping."
          }
        />
        <meta
          property="og:image"
          content={
            selectedArticle
              ? `https://www.easyfishkeeping.in/images/${selectedArticle.id}.webp`
              : "https://www.easyfishkeeping.in/images/hero_image.webp"
          }
        />
        <meta
          property="og:url"
          content={
            selectedArticle
              ? `https://www.easyfishkeeping.in/article/${selectedArticle.id}`
              : "https://www.easyfishkeeping.in/"
          }
        />
        <meta property="og:type" content="article" />

        {/* Twitter Meta Tags */}
        <meta
          property="twitter:title"
          content={
            selectedArticle
              ? selectedArticle.title
              : "Aquascaping Basics - Easy Fishkeeping"
          }
        />
        <meta
          property="twitter:description"
          content={
            selectedArticle
              ? selectedArticle.description
              : "Explore aquascaping techniques, tips, and more with Easy Fishkeeping."
          }
        />
        <meta
          property="twitter:image"
          content={
            selectedArticle
              ? `https://www.easyfishkeeping.in/images/${selectedArticle.id}.webp`
              : "https://www.easyfishkeeping.in/images/hero_image.webp"
          }
        />
        <meta property="twitter:card" content="summary_large_image" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            headline: selectedArticle
              ? selectedArticle.title
              : "Aquascaping Basics - Easy Fishkeeping",
            description: selectedArticle
              ? selectedArticle.description
              : "Explore aquascaping techniques, tips, and more with Easy Fishkeeping.",
            image: selectedArticle
              ? `https://www.easyfishkeeping.in/images/${selectedArticle.id}.webp`
              : "https://www.easyfishkeeping.in/images/hero_image.webp",
            url: selectedArticle
              ? `https://www.easyfishkeeping.in/article/${selectedArticle.id}`
              : "https://www.easyfishkeeping.in/",
            author: {
              "@type": "Person",
              name: "Easy Fishkeeping",
            },
            datePublished: selectedArticle
              ? selectedArticle.publishDate
              : undefined,
            keywords: selectedArticle
              ? (selectedArticle.keywords || []).join(", ")
              : undefined,
            articleSection: selectedArticle
              ? selectedArticle.category
              : undefined,
          })}
        </script>
      </Helmet>
      {loading ? (
        <div className="loading-indicator">Loading articles...</div>
      ) : error ? (
        <div className="error-message">Error: {error}</div>
      ) : selectedArticle ? (
        <div className="article-detail">
          <h3>
            <button
              className="back-button"
              onClick={handleBackClick}
              aria-label="Go back to article list"
            ></button>
            &nbsp;&nbsp;
            {selectedArticle.title || "Untitled"}&nbsp;
            <button
              onClick={() => generateShareableLink(selectedArticle)}
              className="share-button-heading"
              title="Share Article"
              aria-label={`Share ${selectedArticle.title}`}
            ></button>
          </h3>
          <img
            src={`./images/${selectedArticle.id}.webp`} // Ensure image path is correct
            alt={selectedArticle.title || "Image"}
            className="article-image-large"
            onError={handleImageError}
            loading="lazy"
          />
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(selectedArticle.details || ""),
            }}
          /><br/>
         
          <button
            className="back-button"
            onClick={handleBackClick}
            aria-label="Go back to article list"
          ></button>&nbsp;&nbsp;
        <button
              onClick={() => generateShareableLink(selectedArticle)}
              className="share-button"
              title="Share Article"
              aria-label={`Share ${selectedArticle.title}`}
            ></button>
          <p>
            For more information, check out our{" "}
            <a href="/">comprehensive guide to aquascaping</a>.
          </p>
          {/* Google AdSense ad integration */}
       <div className="ad-container">
          {/* Example AdSense ad code */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
          <script>
            {`
              (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-5877327743766526", // Replace with your AdSense client ID
                enable_page_level_ads: true
              });
            `}
          </script>
        </div>
        </div>
      ) : (
        <div className="articles">
          {/* Introduction Section */}
      
      <YouTubeVideos/>
          <h2>Featured Articles</h2>
          {filteredArticles.length === 0 ? (
            <p>No articles found.</p>
          ) : (
            <div className="article-grid">
              {filteredArticles.slice(0, visibleArticles).map((article) => (
                <div
                  key={article.id}
                  className="article-card"
                  onClick={() => handleArticleClick(article)}
                >
                  <div className="article-content">
                    <h3>{article.title || "Untitled"}<button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering article click
                          generateShareableLink(article);
                        }}
                        className="share-button-heading"
                        title="Share Article"
                        aria-label={`Share ${article.title}`}
                      ></button></h3>
                    <img
                    src={`./images/${article.id}.webp`} // Ensure image path is correct
                    alt={article.title || "Image"}
                    className="article-image"
                    onError={handleImageError}
                    loading="lazy"
                  />
                    <p>{article.description || "No description available"}</p>
                    <p className="info">Click to read more.</p>
                    
                  </div>
                  
                </div>
              ))}
              {/* Loading sentinel for observer */}
              {visibleArticles < filteredArticles.length && (
                <div className="loading">Loading more articles...</div>
              )}
            </div>
          )}
        </div>
        
      )}
       
     <div className="button-container">
     {/* <button className="primary" onClick={handleBackClick}>&#8962;</button> */}
     <button className="primary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>&#8593;</button>
     <button className="primary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>&#128269;</button>
</div>

    </div>
  );
};

export default Body;
