import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import "../styles/ArticleDetail.css";
import TagManager from 'react-gtm-module';
import Header from "./Header";
import Menu from "./Menu";

const ArticleDetail = ({
  articles,
  setIsArticleSelected,
  searchQuery,
  selectedArticle,
  handleSearchChange,
  setSearchQuery,
  setSelectedArticle,
  isArticleSelected
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const formattedTitle = id ? id.replace(/-/g, ' ') : '';
  const article = articles.find((article) => article.title === formattedTitle || article.id === parseInt(id));

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WK7CFJHC',
    };
    TagManager.initialize(tagManagerArgs);

    const logArticleVisit = async () => {
      if (article) {
        try {
          const response = await fetch('/api/article-visit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ article_id: article.id }),
          });
          if (!response.ok) {
            throw new Error('Failed to log article visit');
          }
        } catch (error) {
          console.error('Error logging article visit:', error);
        }
      }
    };

    logArticleVisit();
  }, [article]);

  if (!article) {
    return <div>Article not found</div>;
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const generateShareableLink = () => {
    if (!article.title) return;

    const urlTitle = article.title.replace(/\s+/g, '-');
    const url = `${window.location.origin}/article/${urlTitle}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const imageSrc = `${process.env.PUBLIC_URL}/images/${article.id}.webp`;
  const fallbackImageSrc = `${process.env.PUBLIC_URL}/images/fallback.webp`;

  return (
    <div className="container">
      <Header
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        setSearchQuery={setSearchQuery}
        isArticleSelected={isArticleSelected}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        setIsArticleSelected={setIsArticleSelected}
      />
    {/* Google AdSense ad integration */}
    <div className="ad-container">
          {/* Example AdSense ad code */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
          <script>
            {`
              (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-5877327743766526", // Replace with your AdSense client ID
                enable_page_level_ads: true
              });
            `}
          </script>
        </div>
      <div className="article-detail">
        <Helmet>
          <title>{article.title}</title>
          <meta name="description" content={article.description} />
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={article.description} />
          <meta property="og:image" content={imageSrc} />
          <meta property="og:url" content={`${window.location.origin}/article/${article.title.replace(/\s+/g, '-')}`} />
          <meta property="og:type" content="article" />
          <meta property="twitter:title" content={article.title} />
          <meta property="twitter:description" content={article.description} />
          <meta property="twitter:image" content={imageSrc} />
          <meta property="twitter:card" content="summary_large_image" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Article",
              headline: article.title,
              description: article.description,
              image: imageSrc,
              url: `${window.location.origin}/article/${article.title.replace(/\s+/g, '-')}`,
              author: {
                "@type": "Person",
                name: "Easy Fishkeeping",
              },
            })}
          </script>
        </Helmet>
        {/* <Menu isModalOpen={isModalOpen} toggleModal={toggleModal} /> */}

        

        <h2>
          <button
            className="back-button"
            onClick={() => navigate("/")}
          />
          &nbsp;&nbsp;
          {article.title}
          &nbsp;&nbsp;
          <button onClick={generateShareableLink} className="share-button-heading" />
        </h2>
        <img
          src={imageSrc}
          onError={(e) => e.target.src = fallbackImageSrc}
          alt={article.title}
          className="article-image"
          loading="lazy"
        />
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }} />
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.details) }} />
        {/* Google AdSense ad integration */}
        <div className="ad-container">
          {/* Example AdSense ad code */}
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
          <script>
            {`
              (adsbygoogle = window.adsbygoogle || []).push({
                google_ad_client: "ca-pub-5877327743766526", // Replace with your AdSense client ID
                enable_page_level_ads: true
              });
            `}
          </script>
        </div>
        <button className="back-button" onClick={() => navigate("/")} />
        &nbsp;&nbsp;
        <button onClick={generateShareableLink} className="share-button" />
      </div>
      <div className="button-container">
        {/* <button className="primary" onClick={() => navigate("/")}>&#8962;</button> */}
        <button className="primary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>&#8593;</button>
        <button className="primary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>&#128269;</button>
      </div>
    </div>
  );
};

export default ArticleDetail;
