import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import '../styles/Login.css'; // Import the CSS file for styling

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const [redirecting, setRedirecting] = useState(false); // New state to handle redirection
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBackClick = () => {
   
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    if (response.ok) {
      localStorage.setItem('token', data.token);
      setMessage('Login successful, redirecting to home page .. ');
      setRedirecting(true); // Set redirecting to true when login is successful

      // Redirect to home page after 2 seconds
      setTimeout(() => {
        navigate('/'); // Redirect to home page
      }, 400);
    } else {
      setMessage(`Error: ${data.message}`);
    }
  };

  return (
    <div className="login-container">
       
      <form onSubmit={handleSubmit} className="login-form">
      <button
              className="back-button"
              onClick={handleBackClick}
              aria-label="Go back to article list"
            ></button>&nbsp;&nbsp;<input
          name="email"
          onChange={handleChange}
          placeholder="Email"
          type="email"
          required
        />
        <input
          type="password"
          name="password"
          onChange={handleChange}
          placeholder="Password"
          required
        />
        <button type="submit">Login</button>
        {message && <p>{message}</p>}
      </form>
      <div className="button-container">
        <button className="primary" onClick={() => navigate("/")}>
          &#8962;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#8593;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#128269;
        </button>
      </div>
    </div>
  );
};

export default Login;
