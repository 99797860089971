import React, { useEffect, useState } from 'react';
import Menu from './Menu';
import Header from './Header';
import "../styles/Profile.css"
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const Profile = ({articles, setIsArticleSelected ,searchQuery,selectedArticle,handleSearchChange,setSearchQuery,setSelectedArticle,isArticleSelected}) => {
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/profile', {
        headers: { Authorization: token },
      });

      const data = await response.json();
      setProfile(data);
    };

    fetchProfile();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
  const handleBackClick = () => {
   
    navigate("/");
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal open/close
  };
  return profile ? (
    <div className="container">
      <Header
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        setSearchQuery={setSearchQuery}
        isArticleSelected={isArticleSelected}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        setIsArticleSelected={setIsArticleSelected}
      />
    <div className="profile-info"> 
    <Menu isModalOpen={isModalOpen} toggleModal={toggleModal}/>

      <h1><button
              className="back-button"
              onClick={handleBackClick}
              aria-label="Go back to article list"
            ></button>&nbsp;Profile</h1>
      <p>Username: {profile.username}</p>
      <p>Email: {profile.email}</p>
    </div>
    <div className="button-container">
        <button className="primary" onClick={() => navigate("/")}>
          &#8962;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#8593;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#128269;
        </button>
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default Profile;