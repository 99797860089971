import React, { useState, useEffect } from 'react';
import "../styles/YouTubeVideos.css";

const YouTubeVideos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const cachedVideos = localStorage.getItem('cachedVideos');
      const cacheTimestamp = localStorage.getItem('cacheTimestamp');
      const now = new Date().getTime();

      // Check if cached data exists and if it's still valid (within 24 hours)
      if (cachedVideos && cacheTimestamp && (now - cacheTimestamp < 24 * 60 * 60 * 1000)) {
        setVideos(JSON.parse(cachedVideos));
      } else {
        try {
          // Fetch data from the backend API
          const response = await fetch('/api/youtube-videos');
          
          if (!response.ok) {
            throw new Error("Failed to fetch videos from the backend");
          }

          const data = await response.json();
          
          // Set videos state and cache them in localStorage
          setVideos(data);
          localStorage.setItem('cachedVideos', JSON.stringify(data));
          localStorage.setItem('cacheTimestamp', now); // Store current timestamp
        } catch (error) {
          console.error("Error fetching videos:", error);
        }
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <h4>
        <a 
          href="https://www.youtube.com/@easyfishkeepingindia" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src="images/youtube.png" className='youtube-button' alt="YouTube Button" />
        </a> 
        Latest Videos &nbsp;
        <span className="youtube-link">
          <a 
            href="https://www.youtube.com/@easyfishkeepingindia" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            (Visit our YouTube Channel)
          </a>
        </span>
      </h4>
      <div className="video-list">
        {videos && videos.map((video) => (
          <LazyLoadVideo key={video.id.videoId} videoId={video.id.videoId} title={video.snippet.title} />
        ))}
      </div>
    </div>
  );
};

const LazyLoadVideo = ({ videoId, title }) => {
  const [isVisible, setIsVisible] = useState(false);
  const videoRef = React.useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once the video is loaded
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [videoRef]);

  return (
    <div className="video" ref={videoRef}>
      <h4>{title}</h4>
      {isVisible && (
        <iframe
          width="300" // Set width to a smaller size for horizontal layout
          height="169" // Maintain aspect ratio for 16:9
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title}
          key={videoId}
        ></iframe>
      )}
    </div>
  );
};

export default YouTubeVideos;
