import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./modules/Home";
import ArticleDetail from "./modules/ArticleDetail";
import About from "./modules/About";
import Contact from "./modules/Contact";
import Register from "./modules/Register"; // Import Register component
import Login from "./modules/Login"; // Import Login component
import Profile from "./modules/Profile"; // Import Profile component
import "./styles/App.css";
import TagManager from "react-gtm-module";

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WK7CFJHC", // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const [isArticleSelected, setIsArticleSelected] = useState(false);
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    setIsArticleSelected(false); // Close the current article when searching
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/articles/articles.json");
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              isArticleSelected={isArticleSelected}
              setIsArticleSelected={setIsArticleSelected}
              searchQuery={searchQuery}
              selectedArticle={selectedArticle}
              handleSearchChange={handleSearchChange}
              setSearchQuery={setSearchQuery}
              setSelectedArticle={setSelectedArticle}
            />
          }
        />
        <Route
          path="/article/:id"
          element={
            <ArticleDetail
              articles={articles}
              setIsArticleSelected={setIsArticleSelected}
              searchQuery={searchQuery}
              selectedArticle={selectedArticle}
              handleSearchChange={handleSearchChange}
              setSearchQuery={setSearchQuery}
              setSelectedArticle={setSelectedArticle}
              isArticleSelected={isArticleSelected}
            />
          }
        />

        <Route
          path="/article/:title"
          element={
            <ArticleDetail
              articles={articles}
              setIsArticleSelected={setIsArticleSelected}
              searchQuery={searchQuery}
              selectedArticle={selectedArticle}
              handleSearchChange={handleSearchChange}
              setSearchQuery={setSearchQuery}
              setSelectedArticle={setSelectedArticle}
              isArticleSelected={isArticleSelected}
            />
          }
        />
        <Route
          path="/about"
          element={
            <About
              articles={articles}
              setIsArticleSelected={setIsArticleSelected}
              searchQuery={searchQuery}
              selectedArticle={selectedArticle}
              handleSearchChange={handleSearchChange}
              setSearchQuery={setSearchQuery}
              setSelectedArticle={setSelectedArticle}
              isArticleSelected={isArticleSelected}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
