// Register.jsx
import React, { useState } from "react";
import "../styles/Register.css"; // Import the CSS file
import axios from "axios"; // Import axios for making HTTP requests
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from "react-router-dom";
import Menu from "./Menu";

const Register = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); // Add useNavigate hook

  const toggleMenu = () => {
    console.log('Menu toggled');
    setMenuOpen(!menuOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleHomeClick = () => {
    // Get the current URL path
   const currentPath = window.location.pathname;

   // Check if the path starts with /article/
   if (currentPath.startsWith('/article/')) {
     // Extract the part of the URL after /article/
     const remainingPath = currentPath.replace(/^\/article\/\d+/, '');

     // Navigate to the remaining URL or the home page if the remaining path is empty
     navigate(remainingPath ? remainingPath : '/');
   } else {
     // If not on an article page, navigate to home
     navigate('/');
   }
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  
  if (formData.password !== formData.confirmPassword) {
    setError("Passwords do not match.");
    return;
  }

  try {
    const response = await axios.post("/api/register", {
      username: formData.username,
      email: formData.email,
      password: formData.password
    });

    // Extract token from the response
    const { token } = response.data;

    // Optionally store the token or handle it as needed
    localStorage.setItem('token', token);

    setSuccess("Registration successful! Please check your email to confirm your account.");
    setError("");
    setFormData({
      username: "",
      email: "",
      password: "",
      confirmPassword: ""
    });
    navigate('/login');
    // Redirect to login page or home page if necessary
    // Example: navigate('/login'); // Ensure you have `navigate` properly defined
  } catch (err) {
    setError("Registration failed. Please try again.");
    alert("Registration failed. Please try again.");
    setSuccess("");
  }
};


  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal open/close
  };

  return (
    <div className="article-detail">
    <Menu isModalOpen={isModalOpen} toggleModal={toggleModal}/>
    <div className="register-container">
        
      <h2>
      <button
          className="back-button"
          onClick={() => navigate("/")} // Navigate to home
          style={{float:"left",backgroundColor:"transparent"}}
        />
        Register
        </h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="register-button">Register</button>
      </form>
    </div>
    <div className="button-container">
        <button className="primary" onClick={() => navigate("/")}>
          &#8962;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#8593;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#128269;
        </button>
      </div>
    </div>
  );
};

export default Register;
