import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Body from "./Body";
import "../styles/Home.css";
import TagManager from "react-gtm-module";
import "../styles/Modal.css"; // Make sure to create and style this file
import { Link } from 'react-router-dom';
import Menu from "./Menu";

const Home = ({ isArticleSelected, setIsArticleSelected, searchQuery, selectedArticle, handleSearchChange, setSearchQuery, setSelectedArticle }) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WK7CFJHC", // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal open/close
  };

  return (
    <div className="container">
      <Helmet>
        <title>Home - Aquascaping Basics</title>
        <meta
          name="description"
          content="Learn the basics of aquascaping, including tips for setting up your first planted aquarium, selecting fish, and maintaining a healthy aquatic environment."
        />
        <meta name="description"
    content="Discover the art of aquascaping with Easy Fishkeeping. 
    Learn how to set up, maintain, and design beautiful freshwater aquariums."/>
  <meta name="keywords"
    content="easy fishkeeping, easy fish keeping, fishkeeping india, india, aquascapting india, amit aquascaper, amit saxena aquascaper, easyfishkeeping.in, easy fish keeping india, aquascaping, freshwater aquarium, fishkeeping, aquarium care, planted tank, aquarium setup, fish species, aquarium plants, water quality, aquarium maintenance, aquarium design, DIY aquascaping"/>

        
      </Helmet>

      <Header
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        setSearchQuery={setSearchQuery}
        isArticleSelected={isArticleSelected}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        setIsArticleSelected={setIsArticleSelected}
      />

      <Body
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setIsArticleSelected={setIsArticleSelected}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
      />

      {/* <Menu isModalOpen={isModalOpen} toggleModal={toggleModal} /> */}
    </div>
  );
};

export default Home;
