// List of keywords identified from article details
const keywords = [
  "planted aquarium",
  "equipment",
  "substrate",
  "fish",
  "environment",
  "Neon Tetras",
  "Guppies",
  "Corydoras",
  "Betta Fish",
  "Chili Rasboras",
  "Pygmy Corydoras",
  "Ember Tetras",
  "Otocinclus",
  "Endler’s Livebearers",
  "Scarlet Badis",
  "Nature Aquarium",
  "Iwagumi",
  "Dutch Style",
  "Jungle Style",
  "Biotope",
  "Paludarium",
  "Lighting",
  "LED lights",
  "Filtration",
  "Heater",
  "Fertilizers",
  "Tools",
  "Java Moss",
  "Anubias",
  "Cryptocoryne",
  "Alternanthera",
  "Java Fern",
  "Amazon Sword",
  "Marimo Moss Balls",
  "Algae",
  "Nutrient Deficiencies",
  "Water Quality",
  "Filtration Issues",
  "Lighting Problems",
  "Rock Selection",
  "Design",
  "Plant Choice",
  "Maintenance",
  "Driftwood",
  "Rock Formations",
  "Backgrounds",
  "Decorations",
  "Ich",
  "Ichthyophthirius",
  "Fin Rot",
  "Velvet Disease",
  "Columnaris",
  "Cotton Wool Disease",
  "Dropsey",
  "Fish Health",
  "Disease Treatment",
  "Disease Prevention",
  "Bacterial Infections",
  "Breeding",
  "Fry",
  "Neon Tetras Care",
  "Betta Fish Care",
  "Guppies Care",
  "Corydoras Care",
  "Discus Fish Care",
  "Fish Breeding Techniques",
  "Tank Setup",
  "Livebearers",
  "Egg Layers",
  "Breeding Tips",
  "Green Algae",
  "Algae Management",
  "Water Quality for Algae",
  "Lighting for Algae",
  "Fry Care",
  "Fry Tank Setup",
  "Feeding Fry",
  "Fry Growth",
  "Fry Filtration",
  "Fry Feeding Schedule",
  "Fry Health",
  "Tank Mates",
  "Aquascape",
  "Beginner's Guide",
  "Aquascaping Tools",
  "Aquascaping Techniques",
  "Maintaining Plants",
  "Aquarium Setup",
  "Aquascaping Lighting",
  "Aquascaping Mistakes",
  "Creating Aquascapes",
  "Advanced Techniques",
  "Choosing Fish",
  "Aquascaping Plants",
  "Water Parameters",
  "Planting Techniques",
  "Design Principles",
  "Keeping Healthy",
  "Equipment",
  "Layout Ideas",
  "Fish Behavior",
  "Fish Interaction",
  "Social Structures",
  "Tank Behaviors",
  "Aquascapes for Behavior",
  "Territoriality",
  "Feeding Behaviors",
  "Stress Impact",
  "Observing Fish",
  "Behavioral Changes",
  "Quarantine",
  "shrimp",
  "ammonia",
  "nitrogen",
  "cycle",
  "ferns",
  "zen",
  "theme",
  "blackwater",
  "rule",
  "rule of thirds",
  "thirds",
  "magic",
  "fantasy",
  "reef",
  "biotope",
  "coral",
  "hatchling",
  "freshwater",
  "melting",
  "health",
  "disease",
  "fish disease"
];
export default keywords;
