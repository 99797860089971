import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/Contact.css"; // Create this file for styles
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import Header from "./Header";
import Menu from "./Menu";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios for making API calls

const Contact = ({
  articles,
  setIsArticleSelected,
  searchQuery,
  selectedArticle,
  handleSearchChange,
  setSearchQuery,
  setSelectedArticle,
  isArticleSelected,
}) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WK7CFJHC", // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const navigate = useNavigate(); // Add useNavigate hook

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal open/close
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setStatus("Sending message ... ");
      setButtonEnabled(false);
      // Send the form data to the backend
      const response = await axios.post("/api/contact", formData);
      setStatus("Message sent successfully!");
      setButtonEnabled(true);
    } catch (error) {
      console.error("Error sending message:", error);
      setStatus("Failed to send the message. Please try again.");
      setButtonEnabled(true);
    }
  };
  const handleBackClick = () => {
   
    navigate("/");
  };

  return (
    <div className="container">
      <Header
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        setSearchQuery={setSearchQuery}
        isArticleSelected={isArticleSelected}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        setIsArticleSelected={setIsArticleSelected}
      />
      <div className="contact">
        <Helmet>
          <title>Contact Us - Easy Fishkeeping</title>
          <meta
            name="description"
            content="Get in touch with Easy Fishkeeping for any questions or feedback. We would love to hear from you!"
          />
        </Helmet>
        <Menu isModalOpen={isModalOpen} toggleModal={toggleModal} />

        <h1><button
              className="back-button"
              onClick={handleBackClick}
              aria-label="Go back to article list"
            ></button>
            &nbsp;&nbsp;Contact Us</h1>
        <p>
          If you have any questions or feedback, please reach out to us using
          the contact form below or via email at{" "}
          <i style={{ color: "green" }}>easyfishkeeping@outlook.com</i>
        </p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" hidden={!buttonEnabled}>Send</button>
        </form>

        {status && <p>{status}</p>}
      </div>
      <div className="button-container">
        <button className="primary" onClick={() => navigate("/")}>
          &#8962;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#8593;
        </button>
        <button
          className="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          &#128269;
        </button>
      </div>
    </div>
  );
};

export default Contact;
