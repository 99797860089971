import React, { useState, useEffect, useRef } from "react";
import "../styles/Header.css";
import Downshift from "downshift";
import aquascapingKeywords from "./keywords";
import TagManager from 'react-gtm-module';
import { Link } from 'react-router-dom';
import logo from "../images/logo192-1.png"
import { useLocation, useNavigate } from "react-router-dom";

const Header = ({
  searchQuery,
  onSearchChange,
  setSearchQuery,
  isArticleSelected,
  selectedArticle,
  setSelectedArticle,
  setIsArticleSelected
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const menuRef = useRef(null);

  function handleChangeForNav(selection){
    navigate("/");
    onSearchChange(selection);
  }
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WK7CFJHC',  // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    // Check login status by verifying if a token exists in localStorage
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // If token exists, user is logged in
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear token from localStorage
    setIsLoggedIn(false); // Update login state
    navigate('/login'); // Redirect to login page
  };

  const handleClearClick = () => {
    setSelectedArticle(null);
    if (setIsArticleSelected && typeof setIsArticleSelected === "function") {
      setIsArticleSelected(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.location.reload();
    } else {
      navigate('/');
    }
  };

  const handleHomeClick = () => {
    if (location.pathname === '/') {
      window.location.reload();
    } else {
      navigate('/');
    }
  };

  return (
    <div>
      {/* Ribbon of Buttons Above the Header */}
      <header className="top-ribbon">
      <img
            src={logo}
            alt={"Easy Fish Keeping"}
            className="App-logo"
            loading="lazy"
            onClick={handleLogoClick}
          /> 
        <div className="ribbon">
        <button onClick={handleHomeClick}>Home</button>
        <button onClick={() => navigate('/about')}>About</button>
        <button onClick={() => navigate('/contact')}>Contact</button>
        <button onClick={() => navigate('/register')}>Register</button>

        {/* Conditionally render Login or Logout based on login status */}
        {isLoggedIn ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <button onClick={() => navigate('/login')}>Login</button>
        )}
        
        <button hidden={!isLoggedIn} onClick={() => navigate('/profile')}>Profile</button>
        {/* <button onClick={() => alert("We are closed at the moment, but we'll be Opening Soon")}>Shop</button> */}
      </div><div>
         
          <h2>Dive into the World of Aquascaping</h2>
          <span>Explore the art and science of creating stunning underwater landscapes.</span><p/>
          <div className="search" ref={menuRef}>
            <Downshift
              onChange={(selection) => handleChangeForNav(selection)}
              itemToString={(item) => (item ? item : "")}
            >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
              }) => (
                <div>
                  <input
                    placeholder="Search for anything (Betta, Algae etc) ..."
                    {...getInputProps({
                      value: searchQuery,
                      onChange: (e) => onSearchChange(e.target.value),
                    })}
                  />
                  <button
                    className="clear-button"
                    onClick={() => onSearchChange("")}
                  />
                  <ul {...getMenuProps()} className={`autocomplete-menu ${isOpen ? 'open' : ''}`}>
                    {isOpen
                      ? aquascapingKeywords
                          .filter(
                            (keyword) =>
                              !inputValue ||
                              (keyword &&
                                inputValue &&
                                keyword
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase()))
                          )
                          .map((keyword, index) => (
                            <li
                              {...getItemProps({
                                key: keyword,
                                index,
                                item: keyword,
                                className:
                                  highlightedIndex === index ? "selected" : "",
                                style: {
                                  backgroundColor:
                                    highlightedIndex === index
                                      ? "#bde4ff"
                                      : "white",
                                  color: "black",
                                  fontWeight:
                                    selectedItem === keyword ? "bold" : "normal",
                                },
                              })}
                            >
                              {keyword}
                            </li>
                          ))
                      : null}
                  </ul>
                </div>
              )}
            </Downshift>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
