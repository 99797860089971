import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Modal.css'; // Ensure this path matches your project's structure
import { useLocation, useNavigate } from "react-router-dom";



const Menu = ({ isModalOpen, toggleModal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleHomeClick = () => {
    if (location.pathname === '/') {
      window.location.reload(); // Reload the page if on root path
    } else {
      navigate('/'); // Navigate to root if not already there
    }
  };
  return (
    <>
    {/* Button to open modal */}
    <button className="hamburger-menu" onClick={toggleModal}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      {/* Modal dialog */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <nav className="nav-menu">
              <ul>
                <li onClick={handleHomeClick}><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/register">Register</Link></li>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/profile">Profile</Link></li>
                <li><button className="primary" onClick={toggleModal}>Close</button></li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
